import { object, string } from 'yup'

import { UserSubscriptionCancelModalQuery } from 'gql'

import { formatInTimezone } from 'utils/date'

import { CancelMotivationType } from './SubscriptionCancelModalContent'

export const MOTIVATIONS_FOR_TEAM = [
  'I don’t want to be automatically charged',
  'Reforge isn’t as valuable as I expected it to be for my team',
  'I can’t get reimbursed by my company',
  'My team is too busy to take a live course in the near term',
  'None of the plan options fit my team’s needs'
]

export const MOTIVATIONS_FOR_INDIVIDUAL = [
  'I don’t want to be automatically charged',
  'Reforge isn’t as valuable as I expected it to be',
  'I can’t get reimbursed by my company',
  'I can no longer afford to pay for Reforge personally',
  'I’m too busy to take a live course in the near term',
  'I’m moving to a team plan'
]

export const validationSchema = object().shape({
  'motivationForCanceling': string().required(),
  'motivationForCanceling-Other-text': string()
})

export const getCancelationInfo = (
  isTeamPlan: boolean,
  data?: UserSubscriptionCancelModalQuery,
  timezone?: string | null
) => {
  const isDunning = !!data?.user?.subscriptions?.active?.isDunning
  const expiresAtDate = data?.user?.subscriptions?.active?.expiresAt || ''
  return isDunning
    ? `If you cancel, ${
        isTeamPlan ? 'your team' : 'you'
      } will lose Reforge access immediately. Keep your subscription active for continued access to your membership benefits.`
    : `If you cancel, ${isTeamPlan ? 'your team' : 'you'} will lose Reforge access on
                  ${formatInTimezone(
                    expiresAtDate,
                    timezone,
                    'MMMM do, yyyy'
                  )}. Keep your subscription active for continued access to your membership benefits.`
}

export const getWarningBannerCopy = (
  numberOfSeatsAffected: number,
  isMobile: boolean
) => {
  if (numberOfSeatsAffected > 0) {
    if (numberOfSeatsAffected === 1) {
      return isMobile
        ? 'You’re enrolled in an upcoming live program. You’ll lose access before the program ends.'
        : 'You’re enrolled in an upcoming Live Program. If you cancel, your subscription will expire and you’ll lose access to Reforge before this program ends.'
    } else {
      return isMobile
        ? 'Your team is enrolled in a live program. Their access will expire before the program ends.'
        : `${numberOfSeatsAffected} people on your team are enrolled in an upcoming Live Program. If you cancel, your subscription will expire and they will lose access to Reforge before the programs end.`
    }
  }
  return null
}

export const getCancelMotivationAsString = (
  cancelMotivation: CancelMotivationType | null
) => {
  let motivationForCanceling = cancelMotivation?.motivationForCanceling || ''

  if (
    cancelMotivation &&
    cancelMotivation.motivationForCanceling === 'Other' &&
    cancelMotivation.motivationOtherValue
  ) {
    motivationForCanceling = `Other: ${cancelMotivation.motivationOtherValue}`
  }

  return motivationForCanceling
}

// TODO: Use a centralized mock current user
export const mockCurrentUserData = {
  __typename: 'User' as const,
  id: '160286',
  accessPolicyKind: 'member',
  hasContentInProgressOrComplete: false,
  hasApplied: true,
  preferredCourseView: 'new',
  hasMemberAccess: true,
  numSectionBookmarks: {
    '': 4
  },
  subscriptionJoinRequests: [],
  shouldSeeHomepageCoursesSection: true,
  shouldSeeTrialMessaging: false,
  externalId: '011cb846-38a8-4781-afe0-5a59603c6569',
  userCourses: {
    __typename: 'UserCourses' as const
  },
  profile: {
    __typename: 'UserProfile' as const,
    avatarUrl:
      '/assets/default_avatar-31e8f05ce15c23d964a13cde1ad19bf1e8c6da1e543ee5820d90e761638d3d53.svg',
    avatarXSmallUrl:
      '/assets/default_avatar-31e8f05ce15c23d964a13cde1ad19bf1e8c6da1e543ee5820d90e761638d3d53.svg',
    firstName: 'Test',
    hasBadge: false
  },
  cohorts: {
    __typename: 'UserCohorts' as const,
    current: [],
    latestCurrent: null,
    previous: [],
    all: []
  },
  eventSpeakerCohortsForActiveSeasons: [],
  hostCohortsForActiveSeasons: [],
  contact: {
    __typename: 'UserContact' as const,
    allEmails: ['test.test@reforge.com'],
    primaryEmail: 'test.test@reforge.com',
    projectEmail: null
  },
  slackUserId: null,
  joinSlackHref:
    'https://join.slack.com/t/reforge-members/shared_invite/zt-qwyc2wlg-cnC2NR15KPerI81xRLQGWA',
  onboarded: true,
  is: {
    __typename: 'UserIs' as const,
    admin: false,
    accountCreated: false,
    enrolledInLiveProgram: false,
    enrolledInEnrollmentSeason: false,
    enrolledInActiveCourse: false,
    freeUser: false,
    member: false,
    paidMember: false,
    premember: true,
    accepted: true,
    expired: false,
    creator: false,
    collective: false,
    staff: false,
    subscriptionOwner: false,
    teamSubscriptionOwner: false,
    planManager: false,
    noAccess: false,
    banned: false,
    copyHold: false,
    provisionedByScim: false,
    trialing: false,
    eligibleForTrial: false
  },
  can: {
    __typename: 'UserCan' as const,
    accessAllCohortEventReplays: true,
    viewCohortIndex: true,
    pay: true,
    onboard: true,
    enrollInCohort: true,
    linkToSearchResults: true,
    viewActivity: true,
    viewActivitySidebar: true,
    viewCollectionsIndex: true,
    viewConceptsIndex: true,
    viewConceptsProjectsSidebar: null,
    viewDiscussionIndex: true,
    viewEvent: true,
    viewEventsIndex: true,
    viewMemberIndex: true,
    viewMemberProfile: true,
    viewPost: true,
    viewPrograms: true,
    viewProjectsIndex: true,
    viewSearchTabs: true,
    viewSidebarGroups: true,
    viewProjectsAndConcepts: true,
    viewProgramsDashboard: true
  },
  activeProgram: {
    __typename: 'UserProgram' as const,
    id: '542993',
    enrolled: false,
    progress: {
      '563': {
        '7435': 'completed',
        '7436': 'completed',
        '7437': 'completed',
        '7435d': {
          content_percent: 91
        },
        '7436d': {
          content_percent: 91
        },
        '7437d': {
          content_percent: 91
        },
        'percent_completed': 100
      },
      '564': {
        '7440': 'completed',
        '7441': 'completed',
        '7443': 'completed',
        '7440d': {
          video_percent: 707,
          video_seconds: 100,
          content_percent: 91
        },
        '7441d': {
          video_percent: 522,
          video_seconds: 100
        },
        '7443d': {
          video_percent: 224,
          video_seconds: 100
        },
        'percent_completed': 75
      },
      '614': {
        '8127d': {
          content_percent: 0
        },
        '8132d': {
          content_percent: 0
        },
        '8133d': {
          content_percent: 0
        },
        '8161d': {
          content_percent: 0
        },
        '8236d': {
          content_percent: 0
        },
        '8571d': {
          content_percent: 0
        }
      },
      '615': {
        '8123': 'completed',
        '8125': 'completed',
        '8219': 'completed',
        '8120d': {
          content_percent: 0
        },
        '8123d': {
          concept_8123_12: 'complete',
          concept_8123_27: 'complete',
          concept_8123_29: 'complete',
          concept_8123_31: 'complete',
          concept_8123_42: 'complete',
          concept_8123_52: 'complete',
          content_percent: 100
        },
        '8125d': {
          concept_8125_10: 'complete',
          concept_8125_21: 'complete',
          content_percent: 100
        },
        '8126d': {
          content_percent: 0
        },
        '8217d': {
          content_percent: 0
        },
        '8219d': {
          concept_8219_8: 'complete',
          concept_8219_13: 'complete',
          concept_8219_15: 'complete',
          concept_8219_21: 'complete',
          concept_8219_34: 'complete',
          concept_8219_40: 'complete',
          concept_8219_48: 'complete',
          concept_8219_61: 'complete',
          concept_8219_90: 'complete',
          content_percent: 100,
          concept_8219_117: 'complete',
          concept_8219_122: 'complete'
        },
        '8221d': {
          content_percent: 0
        },
        '8226d': {
          content_percent: 0
        },
        '10075d': {
          content_percent: 0
        },
        'percent_completed': 3
      },
      '749': {
        '9037': 'completed',
        '9037d': {
          video_percent: 512,
          video_seconds: 100
        },
        'percent_completed': 12
      },
      'current_module': '564'
    } as any
  },
  preference: {
    __typename: 'UserPreference' as const,
    skipFeedback: false
  },
  allowDirectMessages: false,
  fullName: 'Test Name',
  timezone: 'Europe/Bucharest',
  hasSeenCollectionsPrompt: true,
  networkOnboarded: false,
  networkRecommendations: []
}
