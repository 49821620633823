import { ReforgeAiButton } from 'domains/Ai/ReforgeAiButton'
import SubmitAnArtifactButton from 'domains/Artifact/SubmitAnArtifactButton'
import { SearchField } from 'domains/Search/SearchField'

import { AccountLink } from 'components/AccountLink'
import { ActivityLink } from 'components/ActivityLink'
import { LeftSidebarToggle } from 'components/LeftSidebar/LeftSidebarToggle'
import Logo from 'components/LeftSidebar/Logo'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { useLeftSidebar } from './LeftSidebar/useLeftSidebar'

const TopNavBar = () => {
  const { currentUser } = useCurrentUser()
  const { aiBeta, freeAi } = useFeatureFlags()
  const { isLeftSidebarOpen } = useLeftSidebar()

  if (isLeftSidebarOpen) return null

  return (
    <>
      <div
        id="top-nav"
        className="sticky top-0 z-[1010] flex h-14 w-full items-center justify-between bg-white px-4 md:px-6 lg:h-[72px] lg:pl-8 lg:pr-0"
      >
        <div className="flex shrink-0 items-center lg:ml-[-4px] lg:w-[calc(88px-2rem+4px)] 2xl:w-[calc(240px-2rem+4px)]">
          <LeftSidebarToggle className="mr-2" />
          <Logo />
        </div>

        <div className="mx-auto flex max-w-[1280px] flex-1 items-center justify-between gap-4 lg:px-8">
          <div className="flex flex-1 shrink-0 justify-end lg:justify-start">
            <SearchField className="w-auto min-w-[40px] lg:w-full lg:max-w-[480px]" />
          </div>

          <div className="hidden lg:flex">
            <SubmitAnArtifactButton
              ctaLocation="top_nav"
              className="w-[175px] min-w-[0px] px-2"
            />
          </div>
          {(aiBeta || freeAi) && <ReforgeAiButton />}
          <div className="hidden items-center gap-4 lg:flex">
            {currentUser?.can?.viewActivitySidebar && <ActivityLink />}
            <AccountLink />
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavBar
