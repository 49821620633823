import { KeyboardEvent, MouseEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button, { ButtonVariant } from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/generated/events'

import { createCreatorsLandingPageUrl } from './utils'

interface SubmitAnArtifactButtonProps {
  ctaLocation: string
  className?: string
  variant?: ButtonVariant
}

const SubmitAnArtifactButton = ({
  ctaLocation,
  className = '',
  variant = 'fill'
}: SubmitAnArtifactButtonProps) => {
  const { isLoggedIn } = useCurrentUser()
  const location = useLocation()

  const href = createCreatorsLandingPageUrl(location)

  const handleOnClick = (e: MouseEvent<Element> | KeyboardEvent<Element>) => {
    e.stopPropagation()
    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation,
      text: 'share your work',
      destination: href,
      logged_in: isLoggedIn
    })
  }

  return (
    <Button
      className={twMerge(
        'h-[40px] w-full min-w-[188px] py-2 px-4 text-sm normal-case',
        className
      )}
      href={href}
      variant={variant}
      color={variant === 'fill' ? 'teal' : undefined}
      shape="rounded"
      size="medium"
      target="_blank"
      onClick={handleOnClick}
    >
      Share your work
    </Button>
  )
}

export default SubmitAnArtifactButton
