import { KeyboardEvent, MouseEvent, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronRight } from 'images/p-chevron-right.svg'

interface NavItemProps {
  item: NavItemType
  children?: ReactNode
  onClick?: (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void
  skipTab?: boolean
  linkClassName?: string
  containerClassName?: string
}

export type NavItemType = {
  title: string
  url?: string | null
  dropdown?: Omit<NavItemType[], 'dropdown'>
}

interface ComponentProps {
  to?: string | null
  className?: string
  onClick?: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void
  tabIndex?: number
}

const Component = ({ to, className, ...props }: PropsWithChildren<ComponentProps>) => {
  if (to) {
    return <Link to={to} className={className} {...props} />
  }

  return (
    <span {...props} className={cn(className, 'cursor-pointer hover:text-rb-teal-600')} />
  )
}

const NavItem = ({
  item,
  children,
  onClick,
  skipTab,
  linkClassName,
  containerClassName
}: NavItemProps) => {
  const pathname = window.location.pathname
  const isActive = item.url === pathname || hasActiveChild(item)
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const onClickHandler = (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => {
    if (item.url && !(item.dropdown && isMobileView)) {
      trackNavigationClicked({
        location: 'marketing_site__top_nav',
        type: 'top navigation',
        destination: item.url,
        text: item.title
      })
    }

    onClick?.(e)
  }

  return (
    <li
      className={twMerge(
        'my-4 flex w-full justify-center whitespace-nowrap lg:relative lg:my-0 lg:mx-0 lg:gap-2',
        containerClassName
      )}
    >
      <Component
        to={item.url}
        className={`${twMerge(
          'flex w-full items-center font-polysans text-2xl !leading-[1.4] tracking-tight text-rb-black no-underline hover:no-underline lg:text-sm lg:leading-[1.5] lg:px-4 lg:py-2.5 lg:hover:text-rb-gray-300',
          linkClassName
        )}`}
        onClick={onClickHandler}
        tabIndex={skipTab ? -1 : 0}
      >
        <span
          className={`flex h-full items-center ${
            isActive &&
            'bg-gradient-to-r from-current to-current bg-[length:1px_1px] bg-[0_100%] bg-repeat-x lg:bg-[0_calc(100%-.3em)]'
          }`}
        >
          {item.title}
        </span>
        {item.dropdown && <ChevronRight height={24} className="ml-2.5 lg:hidden" />}
      </Component>

      {children}
    </li>
  )
}

const hasActiveChild = (item: NavItemType) => {
  if (!item.dropdown) {
    return false
  }

  return item.dropdown.some((child) => child.url === window.location.pathname)
}

export default NavItem
