import defaultAvatar from 'default_avatar.svg'
import ReactTooltip from 'react-tooltip'

import DefaultAvatar from 'components/ActivityFacepile/DefaultAvatar'

import { EngagementReportTeamMember } from 'gql'

import { HeaderTooltip } from './HeaderTooltip'
import { Table } from './Table'

interface TeamMemberTableProps {
  teamMembers: EngagementReportTeamMember[]
}
// team-engagement/team
const RowToolTip = ({ data }: { data: string[] }) => {
  const tipLineLimit = 20
  const filteredData = data.filter((d) => d !== '')
  const tip = filteredData
    .sort()
    .slice(0, tipLineLimit)
    .concat(filteredData.length > tipLineLimit ? '...' : [])
    .join('<br/>')
  const id = `team-metric-card-tip-${tip.replace(/[\s\n]+/g, '-')}`
  return (
    <>
      <span data-tip={tip} data-for={id}>
        {data.length}
      </span>
      {filteredData.length > 0 && (
        <ReactTooltip
          id={id}
          effect="solid"
          backgroundColor="#4D4D4D"
          className="max-w-xs sm:max-w-md"
          html={true}
        />
      )}
    </>
  )
}

export const TeamMemberTable = ({ teamMembers }: TeamMemberTableProps) => {
  const rowData = teamMembers.map((tm) => {
    const isDefaultAvatar =
      !!(tm.avatarUrl?.includes('default_avatar') && tm.fullName) || !tm.avatarUrl
    const avatar =
      isDefaultAvatar && tm.fullName ? (
        <DefaultAvatar
          fullName={tm.fullName}
          className="mr-4 inline-block h-8 w-8 rounded-full text-center leading-[32px]"
        />
      ) : (
        <img
          className="mr-4 inline-block w-8 rounded-full border border-white bg-white"
          src={tm.avatarUrl || defaultAvatar}
          alt={`icon for ${tm.fullName}`}
        />
      )

    return [
      <div key="name" className="min-w-max">
        {avatar}
        {tm.fullName}
      </div>,
      <div key="current-program" className="min-w-[160px]">
        {tm.currentCourseName}
      </div>,
      tm.coursesWorkedOnCount > 0 ? <RowToolTip data={tm.coursesWorkedOn} /> : '',
      tm.liveCoursesTakenCount > 0 ? <RowToolTip data={tm.liveCoursesTaken} /> : '',
      tm.contentViewedCount > 0 ? <RowToolTip data={tm.contentViewed} /> : '',
      tm.insightsSavedCount > 0 ? <RowToolTip data={tm.insightsSaved} /> : ''
    ]
  })

  return (
    <div>
      <Table
        tableName="team-member-engagement-data"
        headers={[
          'Name',
          'Current Course',
          <div key="courses-worked-on" className="md:min-w-[95px]">
            Courses Worked On
            <HeaderTooltip tooltip="# of courses started within past year" />
          </div>,
          <div key="live-courses-taken" className="md:min-w-[95px]">
            Live Courses Taken
            <HeaderTooltip tooltip="# of live course enrollments within past year" />
          </div>,
          <div key="content-viewed" className="md:min-w-[95px]">
            Content Viewed
            <HeaderTooltip tooltip="# of content items viewed within past year" />
          </div>,
          <div key="insights-saved" className="md:min-w-[95px]">
            Insights Saved
            <HeaderTooltip tooltip="# of content items bookmarked within past year" />
          </div>
        ]}
        rowData={rowData}
      />
    </div>
  )
}
