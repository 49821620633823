import { MouseEvent } from 'react'

import { ReactComponent as ChevronLeft } from 'images/p-chevron-left.svg'

interface BackButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  skipTab?: boolean
}

const BackButton = ({ onClick, skipTab }: BackButtonProps) => {
  return (
    <li className="my-4 flex w-full justify-center lg:hidden">
      <button
        className="flex w-full cursor-pointer items-center bg-transparent font-polysans text-2xl !leading-[1.25] tracking-tight hover:text-rb-teal-600"
        onClick={onClick}
        tabIndex={skipTab ? -1 : 0}
      >
        <ChevronLeft height={24} className="mr-2.5 lg:hidden" />
        Back
      </button>
    </li>
  )
}

export default BackButton
