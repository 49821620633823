import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import SlackBanner from 'components/dopt/SlackBanner'

import { ProductTourKey } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { BANNER_KEY_DATA_MAP } from './AnnouncementWrapper'

export interface AnnouncementBannerProps {
  activeComponents: string[]
  onClick?: (identifier: ProductTourKey | string) => void
}

const AnnouncementBanner = ({
  data,
  identifier,
  isVisible,
  onClick
}: {
  data: any
  identifier: string
  isVisible: boolean
  onClick?: (identifier: ProductTourKey | string) => void
}) => {
  const history = useHistory()

  if (!isVisible) return null

  if (identifier === 'slack-announcement-banner') {
    return <SlackBanner hasSeenJoinSlackBanner={false} />
  }

  const handleClick = () => {
    onClick?.(identifier)
    history.push(data.ctaUrl)
  }

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-center gap-8 self-stretch rounded-xl h-auto md:h-auto p-6 md:px-12 lg:py-0 md:flex-row md:items-center'
      )}
      style={{ backgroundColor: data.color }}
    >
      {data.image && (
        <div
          className={cn(
            'hidden items-center lg:flex max-w-[184px] h-full [&_img]:object-cover [&_*]:h-full'
          )}
        >
          <img alt="banner" src={data.image} />
        </div>
      )}
      <div className="flex w-full flex-col gap-8 md:flex-1 md:flex-row">
        <div className="lg:py-12">
          <div className="text-[28px] font-light leading-[1.25] tracking-[-0.56px] mb-2">
            {data.header}
          </div>
          <div className="leading-[1.6]">{data.subHeader}</div>
        </div>
      </div>
      <div>
        <Button
          onClick={handleClick}
          variant="outline"
          size="small"
          className="font-semibold"
        >
          {data.ctaText}
        </Button>
      </div>
    </div>
  )
}

const CarouselDot = ({ active, onClick }: { active: boolean; onClick: () => void }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(onClick)}
      onClick={onClick}
      className={`h-3 w-3 rounded-full ${
        active ? 'bg-rb-green-100' : 'bg-rb-gray-100 hover:bg-rb-gray-200'
      }`}
    ></div>
  )
}

const AnnouncementBannerCarousel = ({
  activeComponents,
  onClick
}: AnnouncementBannerProps) => {
  const [indexVisible, setIndexVisible] = useState(0)

  if (!activeComponents || !activeComponents.length) return null

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-4 mb-12 self-stretch text-rb-gray-400'
      )}
    >
      <div className="flex w-full font-polysans">
        {activeComponents.map((componentKey, i) => (
          <AnnouncementBanner
            key={componentKey}
            data={BANNER_KEY_DATA_MAP[componentKey]}
            identifier={componentKey}
            isVisible={indexVisible === i}
            onClick={onClick}
          />
        ))}
      </div>
      {activeComponents.length > 1 && (
        <div className="flex w-full justify-center gap-2">
          {activeComponents.map((_, i) => (
            <CarouselDot
              key={i}
              active={indexVisible === i}
              onClick={() => setIndexVisible(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AnnouncementBannerCarousel
