import React from 'react'
import { twMerge } from 'tailwind-merge'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'

import { useListeningModeContext } from './ListeningModeContextProvider'

interface ListeningModePopoverProps {
  src: string
}

export const ListeningModePopover = React.memo(function ListeningModePopover({
  src
}: ListeningModePopoverProps) {
  const { isOpen, close, trackingMetadata, setCurrentTime, currentTime } =
    useListeningModeContext()

  if (!isOpen) {
    return null
  }

  return (
    <div
      data-testid="listening-mode-popover"
      className={twMerge(
        'bottom-4 w-[92%] sm:w-fit mx-auto flex h-fit opacity-100 fixed sm:sticky z-10'
      )}
    >
      <AudioPlayer
        src={src}
        currentTimeOverride={{ currentTime, setCurrentTime }}
        showCloseButton
        onClose={close}
        trackingMetadata={trackingMetadata}
        autoplay
      />
    </div>
  )
})

export default React.memo(ListeningModePopover)
