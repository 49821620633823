import AnnouncementBanner from 'components/dopt/AnnouncementBanner'

import {
  CompletedProductToursDocument,
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface BannerData {
  ctaText: string
  ctaUrl: string
  subHeader: string
  header: string
  color: string
  image?: string
}
interface BannerKeyDataMap {
  [key: string]: BannerData
}

// Eventually we can move these to admin or something, along with the keys pushed
// to activeComponents below
export const BANNER_KEY_DATA_MAP: BannerKeyDataMap = {
  recommendations: {
    ctaText: 'Dismiss',
    ctaUrl: '/',
    subHeader:
      'Your homepage now includes more personalized course and content recommendations based on your preferences and recent activity',
    header: 'See your latest recommendations',
    color: '#F5D6C3'
  }
}

type ActiveBannerKeys = 'recommendations'[]
const CURRENTLY_ACTIVE_COMPONENTS = ['recommendations'] as ActiveBannerKeys

const AnnouncementWrapper = () => {
  const currentUser = useAssertCurrentUser()
  const { data, loading } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()

  const onClick = (identifier: ProductTourKey | string) => {
    if (!data?.currentUser?.completedProductTours) return null

    completeProductTour({
      variables: {
        input: {
          productTourKey: identifier as ProductTourKey
        }
      },
      refetchQueries: [CompletedProductToursDocument]
    })
  }

  if (loading || !data?.currentUser) return null

  const activeComponents = []

  if (
    !currentUser.is.trialing &&
    !data.currentUser.hideSlackBanner &&
    !currentUser.slackUserId
  ) {
    activeComponents.push('slack-announcement-banner')
  }

  CURRENTLY_ACTIVE_COMPONENTS.forEach((key) => {
    if (!data?.currentUser?.completedProductTours?.[key]) {
      activeComponents.push(key)
    }
  })

  return <AnnouncementBanner activeComponents={activeComponents} onClick={onClick} />
}

export default AnnouncementWrapper
