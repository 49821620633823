import { Redirect, useLocation } from 'react-router-dom'

import AccountLayout from 'domains/Account/AccountLayout'
import MyTeamSettings from 'domains/Profile/MyTeamSettings'
import SubscriptionMembersForm from 'domains/Subscription/SubscriptionMembersForm'

import { ErrorMessage, Loading } from 'components'

import { useUserSubscriptionWithTeamQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

export const MyTeamPage = () => {
  const currentUser = useAssertCurrentUser()
  const { loading, error, data } = useUserSubscriptionWithTeamQuery({
    variables: { id: currentUser.id }
  })
  const currentPathname = useLocation().pathname

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data) return <ErrorMessage error="Failed to load team." />
  if (!data.user) return <ErrorMessage error="Failed to find user team." />

  const { user } = data
  const { can } = currentUser

  const activeSubscription = user.subscriptions?.active
  const primaryEmail = user.contact.primaryEmail || ''
  const allEmails = user.contact.allEmails || []

  if (!activeSubscription?.id || !can.manageMyTeam) {
    return <Redirect to="/account" />
  }

  return (
    <>
      <AccountLayout>
        {currentPathname === '/my_team/members' ? (
          <SubscriptionMembersForm
            userId={user.id}
            activeSubscription={activeSubscription}
            allUserEmails={allEmails}
            userEmail={primaryEmail}
          />
        ) : (
          <>
            <MyTeamSettings
              domainName={activeSubscription.domainName}
              isDiscoverable={activeSubscription.isDiscoverable}
              name={activeSubscription.name}
              userEmailDomains={user.uniqueEmailDomains}
              fullName={user.fullName || ''}
              logoUrl={activeSubscription.logoUrl}
              teamId={activeSubscription.id}
            />
          </>
        )}
      </AccountLayout>
    </>
  )
}
