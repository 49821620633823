import React from 'react'
import { twMerge } from 'tailwind-merge'

import ReactivateLegacySubscriptionButton from 'domains/Subscription/ReactivateLegacySubscriptionButton'
import ReactivateSubscriptionButton from 'domains/Subscription/ReactivateSubscriptionButton'

import Button from 'components/Button'

import { UserSubscriptionWithTeamSubscriptionFragment } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import UpdatePaymentMethodButton from '../UpdatePaymentMethodButton'

interface SubscriptionManagementCardProgressBarProps {
  type: string
  used: number
  isIndividual?: boolean
  total: number
  cta?: {
    copy: string
    onclick: () => void
  }
  color?: string
}

const SubscriptionManagementCardProgressBar = ({
  type,
  used,
  isIndividual,
  total,
  cta,
  color
}: SubscriptionManagementCardProgressBarProps) => {
  const progressPercent = total === 0 ? 0 : (used / total) * 100

  const progressCopy = (
    type: string,
    used: number,
    total: number,
    isIndividual: boolean | undefined
  ) => {
    const verb = isIndividual ? 'used' : 'assigned'
    if (type === 'seat') {
      return `${used} of ${total} seat${total > 1 ? 's' : ''} assigned`
    }
    if (type === 'pass') {
      if (total === 0) {
        return 'No Live Course Passes available'
      } else {
        return `${used} of ${total} pass${total > 1 ? 'es' : ''} ${verb}`
      }
    }
  }

  return (
    <div className="mb-7">
      <div className="flex justify-between">
        <div className="text-sm font-medium text-gray-500">
          {progressCopy(type, used, total, isIndividual)}
        </div>
        {!!cta && (
          <Button
            variant="text-only"
            onClick={cta.onclick}
            className="p-0 text-sm font-medium text-rb-teal-300"
          >
            {cta.copy}
          </Button>
        )}
      </div>
      <div className="mt-2.5 flex h-2.5 flex-row">
        <div
          style={{ width: `${progressPercent}%` }}
          className={twMerge(
            'rounded-l-full',
            color ? `bg-${color}` : 'bg-rb-teal-200',
            progressPercent === 100 ? 'rounded-r-full' : ''
          )}
        ></div>
        <div
          style={{ width: `${100 - progressPercent}%` }}
          className={twMerge(
            'rounded-r-full bg-rb-gray-100',
            progressPercent === 0 ? 'rounded-l-full' : ''
          )}
        ></div>
      </div>
    </div>
  )
}

interface SubscriptionManagementCardProps {
  subscription?: UserSubscriptionWithTeamSubscriptionFragment
  dataTestId?: string
  topBarColorClass?: string
  heading: string
  note?: string
  noteColor?: 'red'
  contentLeft?: string
  contentRight?: string
  progressBar?: SubscriptionManagementCardProgressBarProps
  details?: React.ReactNode
  primaryButton?: {
    copy: string
    onclick: () => void
    dataTest?: string
  }
  secondaryButton?: {
    copy: string
    onclick: () => void
    dataTest?: string
  }
  isLegacyPlan?: boolean
}

export const SubscriptionManagementCard = ({
  subscription,
  dataTestId,
  topBarColorClass,
  heading,
  note,
  contentLeft,
  contentRight,
  progressBar,
  details,
  primaryButton,
  secondaryButton,
  noteColor,
  isLegacyPlan
}: SubscriptionManagementCardProps) => {
  const { showRestartFlow } = useFeatureFlags()

  const isDunningAndOwner = subscription?.isDunning && subscription.userIsOwner

  const renderPrimaryButton = () => {
    const shouldShowReactivate = primaryButton?.copy === 'Reactivate Subscription'

    if (isDunningAndOwner) {
      return <UpdatePaymentMethodButton color="default" shape="rounded" />
    }

    if (primaryButton && shouldShowReactivate) {
      if (showRestartFlow && isLegacyPlan) {
        return <ReactivateLegacySubscriptionButton />
      }

      return <ReactivateSubscriptionButton />
    }

    if (primaryButton && !shouldShowReactivate) {
      return (
        <Button
          size="x-small"
          className="py-1.5"
          onClick={primaryButton.onclick}
          dataTest={primaryButton.dataTest || 'primary-button'}
        >
          {primaryButton.copy}
        </Button>
      )
    }
  }

  return (
    <div
      className={twMerge(
        'relative flex flex-1 grow flex-col rounded border-[1px] border-rb-gray-250 p-5',
        topBarColorClass ? 'pt-9' : ''
      )}
      data-testid={dataTestId}
    >
      <div
        className={twMerge(
          'absolute top-0 left-0 right-0 h-4 rounded-t',
          topBarColorClass ? `bg-${topBarColorClass}` : 'hidden'
        )}
      />
      <div className="flex justify-between">
        <div className="text-sm font-medium">{heading}</div>
        {note && (
          <div
            className={twMerge(
              'text-xs',
              noteColor === 'red' ? 'text-rb-destructive' : ''
            )}
          >
            {note}
          </div>
        )}
      </div>
      {(!!contentLeft || !!contentRight) && (
        <div className="mt-4 mb-5 flex justify-between">
          <h2 className="mb-0 font-normal">{contentLeft}</h2>
          {contentRight && <h2 className="mb-0 font-normal">{contentRight}</h2>}
        </div>
      )}
      {!!progressBar && <SubscriptionManagementCardProgressBar {...progressBar} />}
      {!!details && details}
      <div className="mt-auto flex gap-4">
        {renderPrimaryButton()}
        {secondaryButton && (
          <Button
            size="x-small"
            variant="outline"
            className="py-1.5"
            onClick={secondaryButton.onclick}
            dataTest={secondaryButton.dataTest || 'secondary-button'}
          >
            {secondaryButton.copy}
          </Button>
        )}
      </div>
    </div>
  )
}

export const SubscriptionManagementCards = ({
  children
}: {
  children: React.ReactNode
}) => {
  return <div className="mt-10 flex flex-col gap-5 lg:flex-row">{children}</div>
}
