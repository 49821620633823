import { format } from 'date-fns'
import { KeyboardEvent, MouseEvent, useEffect } from 'react'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { HomepageUpcomingEvent, useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { getCurrentTimezone, getFormattedTimeWithTimeZone } from 'utils/date'
import { trackNavigationClicked } from 'utils/tracking/analytics'

interface UpcomingEventsProps {
  events: HomepageUpcomingEvent[]
}

const UpcomingEvents = ({ events }: UpcomingEventsProps) => {
  const { currentUser } = useCurrentUser()
  const userTimezone: string = getCurrentTimezone(currentUser?.timezone)

  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  events = isMobileView ? events.slice(0, 2) : events

  return (
    <div className="flex flex-col gap-4 py-6 mb-10">
      <div className="flex flex-row items-center w-full align text-xl font-semibold">
        My upcoming events
      </div>

      <div className="flex flex-col gap-4">
        {events.map((event, i) => (
          <UpcomingEventCard
            key={event.id}
            event={event}
            userTimezone={userTimezone}
            index={i}
          />
        ))}
      </div>
    </div>
  )
}

const UpcomingEventCard = ({
  event,
  userTimezone,
  index
}: {
  event: HomepageUpcomingEvent
  userTimezone: string
  index: number
}) => {
  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  const onCtaClick = (e: MouseEvent | KeyboardEvent) => {
    trackNavigationClicked({
      location: currentUser?.is.freeUser ? 'free_user_home' : 'premium_user_home',
      destination: e.currentTarget.getAttribute('href') || '',
      type: 'upcoming_event_card',
      text:
        event.type === 'course_event'
          ? 'view upcoming course event'
          : 'view upcoming event',
      related_identifiers:
        event.type === 'course_event'
          ? {
              ccl_local_id: event.id,
              ccl_course_session_id: event.cclCourseSessionId,
              event_title: event.name
            }
          : {
              event_id: event.id,
              event_title: event.name,
              event_kind: event.type
            }
    })
  }

  useEffect(() => {
    trackServerEvent({
      variables: {
        input: {
          anonymousId: '',
          event: 'impressionViewed',
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            location: currentUser?.is.freeUser ? 'free_user_home' : 'premium_user_home',
            section: 'upcoming_events',
            section_impression_index: index,
            section_index: 0, // This is the first section on the homepage currently
            type: event.type,
            content_type: event.type,
            object_id: event.type === 'event' ? event.id : null,
            ccl_local_id: event.type === 'course_event' ? event.id : null,
            title: event.name
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-row sm:items-center gap-6">
      <div className="h-[52px] w-[60px] py-1 bg-rb-blue-50 rounded flex flex-col items-center shrink-0">
        <span className="text-xs leading-[1.5] text-rb-gray-400 uppercase">
          {format(new Date(event.startsAt), 'MMM')}
        </span>
        <span className="text-xl leading-[1.4] font-semibold text-rb-gray-400">
          {format(new Date(event.startsAt), 'd')}
        </span>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 justify-between w-full">
        <div className="flex flex-col gap-1">
          <span className="text-base leading-[1.6] font-semibold line-clamp-1 md:line-clamp-2">
            {event.name}
          </span>
          <span className="text-base leading-[1.6] text-rb-gray-300">
            {getFormattedTimeWithTimeZone(event.startsAt, event.endsAt, userTimezone)}
          </span>
        </div>
        <Button
          variant="text-only"
          className="text-rb-teal-600 p-0 hover:bg-transparent shrink-0 text-base font-semibold"
          href={event.pathname}
          onClick={onCtaClick}
        >
          {event.type === 'course_event' ? 'View course' : 'View event'}
        </Button>
      </div>
    </div>
  )
}

export default UpcomingEvents
