import { MyTeamSettings } from 'domains/Profile/MyTeamSettings'
import DiscoverableTeams from 'domains/Team/TeamCard/DiscoverableTeams'

import { UserSubscriptionWithTeamQuery } from 'gql'

interface MyTeamProps {
  user: UserSubscriptionWithTeamQuery['user']
}

export const MyTeam = ({ user }: MyTeamProps) => {
  if (!user) return null

  const activeSubscription = user.subscriptions?.active

  const showTeamCard =
    (!activeSubscription && !user.can.manageMyTeam) ||
    (activeSubscription?.isTeam && !user.can.manageMyTeam)
  const teamSubscriptionOwner =
    activeSubscription && activeSubscription.userIsOwner && user.can.manageMyTeam

  if (teamSubscriptionOwner) {
    return (
      <MyTeamSettings
        domainName={activeSubscription.domainName}
        isDiscoverable={activeSubscription.isDiscoverable}
        name={activeSubscription.name}
        userEmailDomains={user.uniqueEmailDomains}
        fullName={user.fullName}
        logoUrl={activeSubscription.logoUrl}
        teamId={activeSubscription.id}
      />
    )
  } else if (showTeamCard) {
    return <DiscoverableTeams />
  } else {
    return null
  }
}
