import { useHistory } from 'react-router-dom'

import ManageTeamButton from 'domains/Team/ManageTeamButton'

import { FacePile, FacePileContainer, FacePileUser } from 'components/FacePile'
import { useModal } from 'components/Modal'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import useSelectRandomUsersFromList from 'hooks/useSelectRandomUsersFromList'

import { trackCtaClicked, trackModalDisplayed } from 'utils/tracking/analytics'

import TeamMemberModal from './TeamMemberModal'

const text = 'manage team'
const destination = '/team_members'

const handleClick = (text: string, destination: string) => {
  trackCtaClicked({
    cta_location: 'premium_user_home',
    cta_type: 'button',
    text: text,
    destination
  })
}

interface TeamSectionProps {
  isSubOwner: boolean
  teamName?: string | null
  teamMembers: FacePileUser[]
}

const TeamSection = ({ isSubOwner, teamName, teamMembers }: TeamSectionProps) => {
  const history = useHistory()
  const { isModalOpen, setIsModalOpen } = useModal()
  const randomTeamMembers = useSelectRandomUsersFromList(teamMembers, 4)

  const handleFacePileClick = () => {
    if (isSubOwner) {
      history.push('/team_members')
    } else {
      setIsModalOpen(true)
      trackModalDisplayed({
        category: 'app',
        location: 'premium_user_home',
        modal_group: 'team details',
        modal_name: 'view team members'
      })
    }
  }

  return (
    <>
      <div className="flex items-center">
        <p className="cursor-default text-sm leading-[1.5] mb-0 mr-2">
          {teamName || 'My Team'}
        </p>
        <button onClick={handleFacePileClick}>
          <FacePileContainer>
            <FacePile users={randomTeamMembers} imageSize="medium" />
            <div className="pl-2 align-middle">
              <RfParagraphMini>
                <span>{teamMembers?.length}</span>
              </RfParagraphMini>
            </div>
            {isSubOwner && (
              <div className="pl-8">
                <ManageTeamButton
                  handleClick={() => handleClick(text, destination)}
                  className="rounded-md h-10 px-4"
                />
              </div>
            )}
          </FacePileContainer>
        </button>
      </div>
      <TeamMemberModal
        isOpen={isModalOpen}
        totalMemberCount={teamMembers.length}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default TeamSection
