import React, { useEffect, useRef } from 'react'

import Button from 'components/Button'
import { SeatAvatar } from 'components/SeatAvatar'
import { AvailableSeatAvatar } from 'components/SeatAvatar/AvailableSeatAvatar'
import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'
import { displayToast } from 'components/Toast'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import { TeamMembersPageSubscriptionSeatFragment } from 'gql'

interface SeatActionsProps {
  seat: TeamMembersPageSubscriptionSeatFragment
  onAssignCohortPassAction(seat: SeatActionsProps['seat']): void
  onSeatAssignAction(seat: SeatActionsProps['seat']): void
  hasCohortAccess: boolean
  isProvisionedByScim: boolean
}

const SeatActions = ({
  seat,
  onAssignCohortPassAction,
  onSeatAssignAction,
  hasCohortAccess,
  isProvisionedByScim
}: SeatActionsProps) => {
  const handleSeatAssign = () => {
    onSeatAssignAction(seat)
  }

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/?mtoken=${seat.subscriptionMember?.token}`
    )
    displayToast(`Copied invite link to clipboard for ${seat.subscriptionMember?.email}.`)
  }

  const isEnrolled = seat.subscriptionMember?.user.is.enrolledInLiveProgram

  return (
    <div className="flex justify-end gap-4">
      {seat.subscriptionMember?.status === 'pending' && (
        <Button
          size="x-small"
          className="whitespace-nowrap"
          variant="outline"
          onClick={handleCopyInviteLink}
          dataTest={`seat-${seat.id}-invite-button`}
        >
          Copy Invite link
        </Button>
      )}
      {!isProvisionedByScim && !seat.subscriptionMember && (
        <Button
          size="x-small"
          variant="outline"
          onClick={handleSeatAssign}
          dataTest={`seat-${seat.id}-invite-button`}
        >
          Invite Member
        </Button>
      )}
      <div className="w-8 shrink-0">
        {seat.subscriptionMember ? (
          <DropdownContextMenu
            positions={['left']}
            dataTest={`seat-${seat.id}-dropdown-button`}
            dismissOnClick
          >
            {!isProvisionedByScim && (
              <DropdownContextMenu.DropdownItem
                data-test="dropdown-context-menu-unassign-assign-seat"
                text={seat.subscriptionMember ? 'Remove Member' : 'Invite Member'}
                onClick={handleSeatAssign}
              />
            )}
            {!isEnrolled && (
              <DropdownContextMenu.DropdownItem
                data-test={`assign-cohort-pass-seat-${seat.id}`}
                text={
                  hasCohortAccess ? 'Remove Live Course Pass' : 'Assign Live Course Pass'
                }
                onClick={() => onAssignCohortPassAction(seat)}
              />
            )}
          </DropdownContextMenu>
        ) : null}
      </div>
    </div>
  )
}

const Table = React.forwardRef<HTMLTableElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    const thClass = 'px-10 pt-10 border-b text-sm font-semibold pb-4'
    return (
      <table
        ref={ref}
        data-test="seat-list-table"
        className="min-w-full table-auto border-collapse divide-y divide-rb-gray-100"
      >
        <thead
          className="sticky top-0
       z-10 bg-white text-left text-[#757B74]"
        >
          <tr>
            <th className={`w-72 text-left ${thClass}`}>Name</th>
            <th className={thClass}>Live Course Pass</th>
            <th className={thClass}>Current Live Course Enrollment</th>
            <th className={`text-white ${thClass}`}>Actions Menu</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-rb-gray-100">{children}</tbody>
      </table>
    )
  }
)
Table.displayName = 'Table'

export interface SeatsListProps
  extends Omit<SeatActionsProps, 'seat' | 'hasCohortAccess'> {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  subscriptionHasDomainName: boolean
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  isProvisionedByScim: boolean
}

const highlightRecentlyUpdatedSeatRow = ({
  seat,
  tableRef
}: {
  seat?: TeamMembersPageSubscriptionSeatFragment | null
  tableRef?: React.RefObject<HTMLTableElement> | null
}) => {
  if (seat) {
    const updatedSeatRow =
      tableRef?.current && tableRef.current.querySelector(`[data-id='${seat.id}']`)

    if (updatedSeatRow) {
      updatedSeatRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

      updatedSeatRow.classList.add('bg-rb-blue-50')
      setTimeout(() => {
        updatedSeatRow.classList.remove('bg-rb-blue-50')
      }, 5000)
    }
  }
}

export const SeatsList = ({
  seats,
  subscriptionHasDomainName,
  onAssignCohortPassAction,
  onSeatAssignAction,
  recentlyUpdatedSeat,
  isProvisionedByScim
}: SeatsListProps) => {
  const tdClass = 'px-[40px] text-sm'

  const getSeatAvatar = (
    subscriptionMember: TeamMembersPageSubscriptionSeatFragment['subscriptionMember']
  ) => {
    const avatarSize = 38
    if (subscriptionMember && subscriptionMember.status === 'pending') {
      return (
        <PendingInviteSeatAvatar
          emailStyles="text-sm font-semibold text-[#757B74] truncate ... w-72"
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          avatarSize={avatarSize}
        />
      )
    } else if (subscriptionMember) {
      const nameToDisplay =
        subscriptionMember.user.fullName || subscriptionMember.user.email
      const isCurrentUser = subscriptionMember.user.isCurrentUser

      return (
        <SeatAvatar
          avatarSize={avatarSize}
          nameStyles="text-sm truncate ... w-72"
          emailStyles="text-sm font-semibold text-[#757B74] truncate ... w-72"
          memberName={`${nameToDisplay} ${isCurrentUser ? '(You)' : ''}`}
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          isWorkEmail={!!subscriptionMember.user.workEmail}
          subscriptionHasDomainName={subscriptionHasDomainName}
          avatarUrl={subscriptionMember.user.profile.avatarUrl}
        />
      )
    }

    return (
      <AvailableSeatAvatar avatarSize={avatarSize - 3} avatarWrapperSize={avatarSize} />
    )
  }

  // Highlight recently updated seat
  const tableRef = useRef<HTMLTableElement>(null)
  useEffect(() => {
    highlightRecentlyUpdatedSeatRow({ seat: recentlyUpdatedSeat, tableRef })
  }, [recentlyUpdatedSeat])

  return (
    <div className="min-h-0 max-h-[600px] overflow-y-auto">
      <Table ref={tableRef}>
        {seats.map((seat) => {
          const { subscriptionMember, hasCohortAccess, id: seatId } = seat
          return (
            <tr
              key={seatId}
              data-id={seatId}
              data-test={`${
                subscriptionMember ? 'occupied-seat' : 'unoccupied-seat'
              }-${seatId}`}
              className="h-[3.75rem]"
            >
              <td className={tdClass}>{getSeatAvatar(subscriptionMember)}</td>
              <td data-test={`cohort-access-seat-${seatId}`} className={tdClass}>
                {hasCohortAccess ? 'Yes' : 'No'}
              </td>
              <td className={tdClass}>
                <span className="flex w-40">
                  {seat.subscriptionMember?.user.is.enrolledInActiveCourse
                    ? `Enrolled: ${seat.subscriptionMember?.user.currentEnrolledCourse?.course?.title}`
                    : 'Not Enrolled'}
                </span>
              </td>
              <td className={tdClass}>
                <SeatActions
                  seat={seat}
                  onAssignCohortPassAction={onAssignCohortPassAction}
                  onSeatAssignAction={onSeatAssignAction}
                  hasCohortAccess={hasCohortAccess}
                  isProvisionedByScim={isProvisionedByScim}
                />
              </td>
            </tr>
          )
        })}
      </Table>
    </div>
  )
}
