import { Link } from 'react-router-dom-v5-compat'

import { ReactComponent as AlertsIcon } from 'images/icon--alerts.svg'

import UnseenActivityBadge from './UnseenActivityBadge'

export function ActivityLink({ onClick }: { onClick?: () => void }) {
  return (
    <Link
      id="sb-header-desktop-activity"
      to="/posts/activity"
      className="relative block h-10 w-10 text-rb-gray-400/85"
      onClick={onClick}
    >
      <AlertsIcon width={24} height={24} className="mx-auto fill-current pt-[10px]" />
      <UnseenActivityBadge className="absolute left-1/2 top-1" />
    </Link>
  )
}
