import { ChangeEvent, useReducer } from 'react'

import AccountLayout from 'domains/Account/AccountLayout'
import { SeatsList } from 'domains/Subscription/SeatsList'

import { ErrorMessage, Loading } from 'components'
import { ManageSeatSidePanel, SeatManagementMode } from 'components/ManageSeatSidePanel'
import { ToggleSwitch } from 'components/ToggleSwitch'
import Tooltip from 'components/Tooltip/Tooltip'
import { BasicInfoIcon } from 'components/icons'

import {
  TeamMembersPageSubscriptionSeatFragment,
  useTeamMembersPageQuery,
  useToggleManualAssignSubscriptionMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  trackCtaClicked,
  trackSidePanelDismissed,
  trackSidePanelDisplayed
} from 'utils/tracking/analytics'

import { ReactComponent as MagnifyingGlassIcon } from 'images/icon--magnifying-glass.svg'

import { JoinTeamRequests } from './JoinTeamRequests/JoinTeamRequests'
import { SeatSummary } from './SeatSummary/SeatSummary'

type TeamMembersPageState = {
  searchTerm: string
  sidePanelIsOpen: boolean
  seatBeingManaged: TeamMembersPageSubscriptionSeatFragment | null
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  sidePanelMode: SeatManagementMode | null
  isCheckoutModalOpen: boolean
  isCohortPurchaseModalOpen: boolean
  cohortPassPurchasedCount: number | null
}

const initialState: TeamMembersPageState = {
  searchTerm: '',
  sidePanelIsOpen: false,
  seatBeingManaged: null,
  recentlyUpdatedSeat: null,
  sidePanelMode: null,
  isCheckoutModalOpen: false,
  isCohortPurchaseModalOpen: false,
  cohortPassPurchasedCount: null
}

type TeamMembersPageAction =
  | { type: 'CHANGE_SEARCH_INPUT'; payload: { searchTerm: string } }
  | {
      type: 'CLICK_REMOVE_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON'
      payload: { successfulMutation: boolean }
    }
  | {
      type: 'CLICK_ASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
      payload: { seatBeingManaged: TeamMembersPageSubscriptionSeatFragment }
    }
  | {
      type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA'
    }
  | {
      type: 'SIDE_PANEL_ACTION_SUCCESS'
    }

const reducer = (state: TeamMembersPageState, action: TeamMembersPageAction) => {
  switch (action.type) {
    case 'CHANGE_SEARCH_INPUT': {
      return { ...state, searchTerm: action.payload.searchTerm }
    }
    case 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA': {
      trackSidePanelDismissed({
        panel_group: 'manage_seats',
        panel_name: state.sidePanelMode! // if the panel opened then the mode should be  already be set
      })

      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        sidePanelMode: null
      }
    }
    case 'SIDE_PANEL_ACTION_SUCCESS': {
      return {
        ...state,
        sidePanelIsOpen: false,
        seatBeingManaged: null,
        sidePanelMode: null,
        recentlyUpdatedSeat: state.seatBeingManaged
      }
    }
    case 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.COHORT_ACCESS

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'edit cohort access'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON': {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_seat_side_panel',
        text: 'save (edit cohort access)'
      })

      return {
        ...state,
        sidePanelMode: SeatManagementMode.COHORT_ACCESS
      }
    }
    case 'CLICK_ASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.ASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'invite member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })

      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    case 'CLICK_UNASSIGN_SEAT_ACTION_CTA': {
      const sidePanelMode = SeatManagementMode.UNASSIGN_SEAT

      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'manage_seats_page',
        text: 'remove member'
      })

      trackSidePanelDisplayed({
        panel_group: 'manage_seats',
        panel_name: sidePanelMode
      })
      return {
        ...state,
        sidePanelIsOpen: true,
        seatBeingManaged: action.payload.seatBeingManaged,
        sidePanelMode
      }
    }
    default: {
      console.error('Unhandled action type', action, state)
      return state
    }
  }
}

interface SearchBarProps {
  searchTerm: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchBar = ({ searchTerm, onChange }: SearchBarProps) => {
  return (
    <div
      className="flex max-w-[364px] items-center justify-between rounded py-3 px-4 border border-gray-200 self-stretch"
      role="search"
    >
      <input
        data-test="seats-list-search-input"
        type="search"
        name="seats-list-search-input"
        className="focus:outline-none flex grow shrink-0 basis-0"
        placeholder="Search team members"
        aria-label="Search team members"
        value={searchTerm}
        onChange={onChange}
      />
      <MagnifyingGlassIcon className="h-4 w-4" />
    </div>
  )
}

const seatsFilter = (
  searchTerm: string,
  seats: TeamMembersPageSubscriptionSeatFragment[]
) => {
  return seats.filter((seat) => {
    if (!seat.subscriptionMember) return false

    const {
      user: { email, workEmail, fullName }
    } = seat.subscriptionMember

    return (
      fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      workEmail?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })
}

export interface TeamMembersPageProps {}
export const TeamMembersPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { seatBeingManaged, sidePanelMode, sidePanelIsOpen } = state

  const { id: currentUserId } = useAssertCurrentUser()
  const { data, loading, error, refetch } = useTeamMembersPageQuery({
    variables: { id: currentUserId }
  })

  const activeSubscription = data?.user?.subscriptions?.active

  const { autoAssignCoursePass } = useFeatureFlags()
  const [setManualAssign] = useToggleManualAssignSubscriptionMutation()

  // NOTE: This may need to be debounced if we see performance issues
  const onSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'CHANGE_SEARCH_INPUT', payload: { searchTerm: value } })
  }

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  if (!activeSubscription) {
    return <ErrorMessage error="This user does not have an active subscription" />
  }

  const assignedSeatcount =
    activeSubscription.seats.length -
    activeSubscription.seats.filter((s) => !s.subscriptionMember).length

  const assignedCohortCreditCount =
    activeSubscription.cohortCredits.unexpired.length -
    activeSubscription.cohortCredits.assignable.length

  const seats = seatsWithCurrentUserFirst({
    seats: activeSubscription.seats,
    currentUserId
  })

  const toggleManualAssign = async () => {
    setManualAssign({
      variables: {
        input: {
          id: activeSubscription.id,
          manualAssign: !activeSubscription.manualAssign
        }
      }
    }).then(() => {
      // Update the subscription by refreshing the page query
      refetch()
    })
  }

  return (
    <div className="flex flex-col pb-12">
      <AccountLayout>
        <div className="mb-10 flex flex-col justify-between md:flex-row">
          <SeatSummary
            subscription={activeSubscription}
            totalSeatCount={activeSubscription.seats.length}
            assignedSeatcount={assignedSeatcount}
            totalCohortCreditCount={activeSubscription.cohortCredits.unexpired.length}
            assignedCohortCreditCount={assignedCohortCreditCount}
          />
        </div>
        <JoinTeamRequests
          subscriptionId={activeSubscription.id}
          subscriptionHasDomainName={!!activeSubscription.domainName}
          hasSeatsAvailable={assignedSeatcount < activeSubscription.seats.length}
        />
        <div className="flex flex-wrap">
          <div className="float-left md:w-1/2">
            <SearchBar searchTerm={state.searchTerm} onChange={onSearchInputChange} />
          </div>
          {autoAssignCoursePass && (
            <div className="float-right items-end md:w-1/2">
              <span className="pl-2 text-md font-medium float-right">
                Require pass assignment
                <Tooltip
                  place="bottom"
                  html={true}
                  delayUpdate={300}
                  delayHide={300}
                  className="!opacity-100"
                  tooltipBody="If toggled off, any live course pass will be available<br /> for use by any team member."
                >
                  <BasicInfoIcon className="ml-2 fill-rb-black" fill="0F0F0F" />
                </Tooltip>
                <ToggleSwitch
                  className="pl-4 inline"
                  toggled={activeSubscription.manualAssign === true}
                  handleClick={() => toggleManualAssign()}
                />
              </span>
            </div>
          )}
        </div>
        <div aria-atomic="true" aria-live="polite" aria-relevant="all">
          <SeatsList
            seats={state.searchTerm ? seatsFilter(state.searchTerm, seats) : seats}
            recentlyUpdatedSeat={state.recentlyUpdatedSeat}
            subscriptionHasDomainName={!!activeSubscription.domainName}
            isProvisionedByScim={activeSubscription.isProvisionedByScim}
            onAssignCohortPassAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: 'CLICK_EDIT_COHORT_ACCESS_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
            onSeatAssignAction={(
              seatBeingManaged: TeamMembersPageSubscriptionSeatFragment
            ) =>
              dispatch({
                type: seatBeingManaged.subscriptionMember
                  ? 'CLICK_UNASSIGN_SEAT_ACTION_CTA'
                  : 'CLICK_ASSIGN_SEAT_ACTION_CTA',
                payload: { seatBeingManaged }
              })
            }
          />
        </div>
        {sidePanelIsOpen && sidePanelMode && seatBeingManaged && (
          <ManageSeatSidePanel
            currentUserId={currentUserId}
            seat={seatBeingManaged}
            subscription={activeSubscription}
            subscriptionHasDomainName={!!activeSubscription.domainName}
            mode={sidePanelMode}
            onActionSuccess={() => dispatch({ type: 'SIDE_PANEL_ACTION_SUCCESS' })}
            onCancelOrCloseCtaClick={() =>
              dispatch({ type: 'CLICK_SIDE_PANEL_CLOSE_OR_CANCEL_CTA' })
            }
            dispatch={dispatch}
          />
        )}
      </AccountLayout>
    </div>
  )
}

// helpers
const seatsWithCurrentUserFirst = ({
  seats,
  currentUserId
}: {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  currentUserId: string
}) => {
  const currentUserSeat = seats.find(
    (s) => currentUserId === s.subscriptionMember?.user.id
  )

  const seatsWithoutCurrentUserSeat = currentUserSeat
    ? seats.filter((s) => s.subscriptionMember?.user.id !== currentUserId)
    : seats

  return currentUserSeat ? [currentUserSeat, ...seatsWithoutCurrentUserSeat] : seats
}
