import defaultAvatar from 'default_avatar.svg'
import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'
import AvailableSeatTooltipContent from 'components/SeatAvatar/AvailableSeatTooltipContent'
import Tooltip from 'components/Tooltip/Tooltip'
import { BasicInfoIcon } from 'components/icons'

export interface SeatAvatarProps {
  avatarSize?: string | number | undefined
  avatarUrl?: string | undefined
  memberName: string
  nameStyles?: string | undefined
  email: string
  emailStyles?: string | undefined
  isWorkEmail: boolean
  subscriptionHasDomainName: boolean
}

/**
 * SeatAvatar
 * returns a presentational component for table within Manage Seats
 * containing the seat owner's image, name, email
 */
export function SeatAvatar({
  avatarSize = 46,
  avatarUrl,
  memberName,
  nameStyles = '',
  email,
  emailStyles = '',
  isWorkEmail,
  subscriptionHasDomainName
}: SeatAvatarProps) {
  return (
    <div className={`flex h-[${avatarSize}px]`} data-testid="seat-avatar">
      {avatarUrl ? (
        <BadgeableAvatar
          className="mr-4 w-full"
          avatarUrl={avatarUrl}
          width={avatarSize}
          height={avatarSize}
          alt={`Avatar for ${memberName}`}
          fullName={memberName}
        />
      ) : (
        <span className="mr-4">
          <img
            src={defaultAvatar}
            width={avatarSize}
            height={avatarSize}
            alt={`No avatar for ${memberName}`}
          />
        </span>
      )}
      <div className="flex flex-col text-left">
        <div
          data-test="member-name"
          className={twMerge('text-sm font-normal text-rb-black', nameStyles)}
        >
          {memberName}
        </div>
        <div
          data-test="member-email"
          className={twMerge(
            'flex items-center text-sm font-semibold text-rb-gray-300',
            emailStyles
          )}
        >
          <span className="min-w-0 truncate">{email}</span>
          {!isWorkEmail && subscriptionHasDomainName && (
            <Tooltip
              tooltipBody={<AvailableSeatTooltipContent />}
              contentWrapperClassname="text-rb-gray-300 hover:text-rb-gray-300"
            >
              <BasicInfoIcon className="ml-1 fill-rb-gray-300" fill="currentColor" />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}
