import Button from 'components/Button'
import { SeatAvatar } from 'components/SeatAvatar'

interface JoinRequestItemProps {
  user: {
    fullName?: string | null
    workEmail?: string | null
    email: string
    profile: {
      avatarUrl: string
    }
  }
  avatarSize: number
  subscriptionHasDomainName: boolean
  onApprove: () => void
  onDeny: () => void
  hasSeatsAvailable: boolean
}

export const JoinRequestItem = ({
  user,
  avatarSize,
  subscriptionHasDomainName,
  onApprove,
  onDeny,
  hasSeatsAvailable
}: JoinRequestItemProps) => (
  <div data-testid="join-request-item" className="flex justify-between mb-4">
    <SeatAvatar
      avatarSize={avatarSize}
      nameStyles="text-xs font-medium truncate w-72"
      emailStyles="text-xs text-[#757B74] truncate w-72"
      memberName={user.fullName || user.email}
      email={user.workEmail || user.email}
      isWorkEmail={!!user.workEmail}
      subscriptionHasDomainName={subscriptionHasDomainName}
      avatarUrl={user.profile.avatarUrl}
    />
    <div className="flex gap-4">
      {!hasSeatsAvailable && (
        <p className="text-rb-destructive-100 pt-2 text-sm font-semibold">
          No seats remaining. Upgrade plan or manage list to approve.
        </p>
      )}

      <Button
        data-testid="join-request-approve-button"
        className="h-8"
        disabled={!hasSeatsAvailable}
        onClick={onApprove}
      >
        Approve
      </Button>
      <Button
        data-testid="join-request-deny-button"
        className="h-8"
        variant="outline"
        onClick={onDeny}
      >
        Deny
      </Button>
    </div>
  </div>
)

export default JoinRequestItem
