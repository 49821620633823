import { twMerge } from 'tailwind-merge'

import { useSidebarQuery } from 'gql'

const UnseenActivityBadge = ({ className }: { className?: string }) => {
  const { data } = useSidebarQuery()
  const unseenActivityCount = data?.currentUser?.unseenActivityCount || 0
  if (unseenActivityCount === 0) {
    return null
  }

  return (
    <div
      className={twMerge(
        'h-[18px] w-[18px] rounded-full bg-rb-orange text-center text-xs font-normal leading-[18px] text-white',
        className
      )}
    >
      {unseenActivityCount}
    </div>
  )
}

export default UnseenActivityBadge
