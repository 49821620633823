import { useEffect, useState } from 'react'

import AvatarPicker from 'domains/User/AvatarPicker'
import PlaceholderAvatar from 'domains/User/PlaceholderAvatar'

import { ErrorMessage, Loading } from 'components'
import BadgeableAvatar from 'components/BadgeableAvatar'
import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'

import {
  PreviousCompaniesFieldsFragment,
  ProfileEditModalUserFieldsFragment,
  ProfileEditModalUserProfileFieldsFragment,
  useProfileEditModalQuery,
  useUpdateUserMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useSearchCompany } from 'hooks/useSearchCompany'

import {
  trackAccountUpdated as segmentTrackAccountUpdated,
  trackModalDisplayed,
  trackMyAccountProfileUpdated
} from 'utils/tracking/analytics'

import ProfilePreviousCompaniesInput from '../ProfilePreviousCompaniesInput'

const HEADLINE_CHARACTER_LIMIT = 75

interface TrackAccountUpdatedOptions {
  user: ProfileEditModalUserFieldsFragment
  profile: ProfileEditModalUserProfileFieldsFragment
  previousCompanies: PreviousCompaniesFieldsFragment[]
  avatarUrl: string | null | undefined
}

const trackAccountUpdated = ({
  user,
  profile,
  previousCompanies,
  avatarUrl
}: TrackAccountUpdatedOptions) => {
  segmentTrackAccountUpdated({
    access_policy_kind: user.accessPolicyKind,
    company: profile.companyName || undefined,
    first_name: profile.firstName || undefined,
    former_companies: previousCompanies
      .map((pc) => pc.company.name)
      .filter((name) => !!name) as string[],
    image_url: avatarUrl || undefined,
    last_name: profile.lastName || undefined,
    user_job_title: profile.role || undefined
  })
}

export default function FreeUserProfileEditModalContainer() {
  const {
    loading: profileLoading,
    data: profileData,
    refetch: refetchProfile
  } = useProfileEditModalQuery()

  if (profileLoading) {
    return <Loading />
  }

  if (!profileData?.currentUser) {
    return <ErrorMessage error={new Error('Failed to load user.')} />
  }

  return (
    <FreeUserProfileEditModal
      refetchProfile={refetchProfile}
      user={profileData.currentUser}
    />
  )
}

interface FreeUserProfileEditModalProps {
  user: ProfileEditModalUserFieldsFragment
  refetchProfile: () => void
}

const FreeUserProfileEditModal = ({
  user,
  refetchProfile
}: FreeUserProfileEditModalProps) => {
  const initialData = {
    firstName: user.profile.firstName || '',
    lastName: user.profile.lastName || '',
    headline: user.profile.networkProfile.headline || '',
    role: user.profile.role || '',
    companyName: user.profile.companyName || '',
    transitioning: Boolean(user.profile.networkProfile.transitioning),
    aboutMeText: user.profile.aboutMeText ?? ''
  }

  const { currentUser } = useCurrentUser()

  const [updateUser, { loading: userLoading }] = useUpdateUserMutation()

  const [canSave, setCanSave] = useState(true)
  const [avatarUrl, setAvatarUrl] = useState<string>(user.profile.avatarUrl || '')
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false)
  const [data, setData] = useState<{ [key: string]: string | boolean }>(initialData)

  const { closeGlobalModal } = useGlobalModal()

  const { companies, companiesLoading } = useSearchCompany()

  const previousCompanies = user?.profile.previousCompanies || []

  useEffect(() => {
    if (companiesLoading) return

    const allowFormSubmitOnEnter = false
    const usingWithForm = false

    window.Reforge.autocomplete(
      '.js-reforge-company-autocomplete',
      companies,
      10,
      allowFormSubmitOnEnter,
      usingWithForm
    )
    window.Reforge.autocomplete(
      '.js-reforge-prev-company-autocomplete',
      companies,
      10,
      allowFormSubmitOnEnter,
      usingWithForm
    )
  }, [companiesLoading, companies])

  const onNewAvatar = (dataUrl: string) => {
    setAvatarUrl(dataUrl)
    setIsUpdatingAvatar(false)
  }

  const handleCancel = () => {
    closeGlobalModal()
    document.getElementById('page')?.scrollTo(0, 0)
  }

  const updateInputData = (key: string, value: string | boolean) => {
    setData((prevData) => ({ ...prevData, [key]: value }))

    if ((key === 'firstName' || key === 'lastName') && value === '') {
      setCanSave(false)
    } else if (key === 'headline' && String(value).length > HEADLINE_CHARACTER_LIMIT) {
      setCanSave(false)
    } else if (currentUser?.is.creator && key === 'role' && value === '') {
      setCanSave(false)
    } else if (currentUser?.is.creator && key === 'companyName' && value === '') {
      setCanSave(false)
    } else if (currentUser?.is.creator && key === 'aboutMeText' && value === '') {
      setCanSave(false)
    } else {
      setCanSave(true)
    }
  }

  const handleSave = async () => {
    setCanSave(false)
    if (user) {
      await updateUser({
        variables: {
          input: {
            firstName: data.firstName as string,
            lastName: data.lastName as string,
            role: data.role as string,
            companyName: data.companyName as string,
            networkProfile: {
              headline: data.headline as string,
              transitioning: data.transitioning as boolean
            },
            aboutMeText: data.aboutMeText as string
          }
        }
      })
      refetchProfile()

      trackAccountUpdated({
        user,
        previousCompanies,
        avatarUrl,
        profile: user.profile
      })
      trackMyAccountProfileUpdated({
        access_policy_kind: user.accessPolicyKind,
        user_id: user.id,
        profile_fields: data
      })
      closeGlobalModal()
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }

  const charactersRemainingMessage = (watchField: string, characterLimit: number) => {
    if (!data[watchField]) {
      return <span>{characterLimit} characters remaining</span>
    } else {
      const fieldLength = String(data[watchField]).length
      if (fieldLength > characterLimit) {
        return (
          <span className="text-rb-warning">
            Too many characters, remove at least {fieldLength - characterLimit}{' '}
          </span>
        )
      } else if (fieldLength === characterLimit) {
        return <span>No characters remaining</span>
      } else if (fieldLength === characterLimit - 1) {
        return <span>1 character remaining</span>
      } else if (fieldLength < characterLimit - 1) {
        return <span>{characterLimit - fieldLength} characters remaining</span>
      }
    }
  }

  return (
    <ModalContent className="py-4">
      {isUpdatingAvatar && (
        <>
          <hr className="border-t-1.25 border-solid border-rb-gray-50" />
          <AvatarPicker
            avatarUrl={avatarUrl}
            onCancel={() => setIsUpdatingAvatar(false)}
            onDone={onNewAvatar}
          />
        </>
      )}
      {!isUpdatingAvatar && (
        <div className="py-2">
          <div className="mb-6">
            <div className="">
              <div className="mt-[3px] sm:ml-1 sm:mt-0">
                <h3 className="mb-3 text-3xl font-medium">My account</h3>
                <p className="text-base leading-[1.6]">
                  For a better experience with Reforge, please update or add the details
                  below. (*required)
                </p>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex h-20 w-20 shrink-0 flex-row">
              {avatarUrl && !avatarUrl.includes('default_avatar') ? (
                <BadgeableAvatar
                  user={{}}
                  avatarUrl={avatarUrl}
                  width="80"
                  height="80"
                  onEditClick={() => setIsUpdatingAvatar(true)}
                  editable
                />
              ) : (
                <PlaceholderAvatar
                  onAddPhotoClicked={() => setIsUpdatingAvatar(true)}
                  width="80px"
                  height="80px"
                  smaller
                />
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="text-rb-gray-3 w-full text-left">
              <div className="flex flex-col">
                <div className="flex flex-col justify-between md:flex-row">
                  <div className="mb-[15px] flex flex-1 flex-col md:mr-[10px]">
                    <label className="mb-[5px] text-sm font-semibold" htmlFor="firstName">
                      First name *
                    </label>
                    <input
                      className="focus:shadow-outline appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                      type="text"
                      id="firstName"
                      placeholder="First name"
                      onChange={(e) => updateInputData('firstName', e.target.value)}
                      value={data.firstName as string}
                    />
                    {(!data.lastName || data.lastName === '') && (
                      <div className="mt-[5px] text-xs italic text-rb-warning">
                        This input is required
                      </div>
                    )}
                  </div>
                  <div className="mb-[15px] flex flex-1 flex-col  md:ml-[10px]">
                    <label className="mb-[5px] text-sm font-semibold" htmlFor="lastName">
                      Last name *
                    </label>
                    <input
                      className="focus:shadow-outline appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                      type="text"
                      id="lastName"
                      placeholder="Last Name"
                      onChange={(e) => updateInputData('lastName', e.target.value)}
                      value={data.lastName as string}
                    />
                    {(!data.lastName || data.lastName === '') && (
                      <div className="mt-[5px] text-xs italic text-rb-warning">
                        This input is required
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-[15px] flex flex-col">
                  <label className="mb-[5px] text-sm font-semibold" htmlFor="headline">
                    What is your headline?
                  </label>
                  <input
                    className="focus:shadow-outline appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                    type="text"
                    id="headline"
                    placeholder="Advisor, mentor, and product leader working on PLG @ Slack..."
                    onChange={(e) => updateInputData('headline', e.target.value)}
                    value={data.headline as string}
                  />
                  <div className="mt-1 text-xs font-medium text-[#888888]">
                    {charactersRemainingMessage('headline', HEADLINE_CHARACTER_LIMIT)}
                  </div>
                </div>
                <div className="mb-[15px] flex flex-col">
                  <label className="mb-[5px] text-sm font-semibold" htmlFor="role">
                    What is your title? {user.is.creator && '*'}
                  </label>
                  <input
                    className="focus:shadow-outline appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                    type="text"
                    id="role"
                    placeholder="Product Manager, Data Analyst, Head of Product..."
                    onChange={(e) => updateInputData('role', e.target.value)}
                    value={data.role as string}
                  />
                </div>
                <div className="relative mb-[15px]">
                  <label className="mb-[5px] text-sm font-semibold" htmlFor="companyName">
                    Where do you work? {user.is.creator && '*'}
                  </label>
                  <input
                    className="js-reforge-company-autocomplete focus:shadow-outline block w-full appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                    type="text"
                    id="companyName"
                    placeholder="Lyft, The New York Times, Slack, ..."
                    onChange={(e) => updateInputData('companyName', e.target.value)}
                    value={data.companyName as string}
                  />
                  <div className="mt-1 text-xs font-medium text-[#888888]">
                    Pick one company most associated with your current work
                  </div>
                </div>

                <div className="mb-[15px] flex flex-col">
                  <ProfilePreviousCompaniesInput
                    previousCompanies={previousCompanies}
                    freeVariant={true}
                  />
                </div>

                <div className="relative mb-[15px]">
                  <label className="mb-[5px] text-sm font-semibold" htmlFor="shortBio">
                    Short bio {user.is.creator && '*'}
                  </label>
                  <textarea
                    className="focus:shadow-outline block w-full appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300"
                    id="shortBio"
                    placeholder="Add your bio..."
                    onChange={(e) => updateInputData('aboutMeText', e.target.value)}
                    value={data.aboutMeText as string}
                    rows={6}
                  />
                </div>

                <div className="my-2.5 flex flex-col">
                  <div className="flex h-5 items-center">
                    <div className="flex h-full items-center justify-between">
                      <label
                        className="flex h-full text-xs font-medium hover:cursor-pointer sm:text-base"
                        htmlFor="transitioning"
                      >
                        <input
                          className="mr-2 h-full w-5 accent-rb-teal-300 hover:cursor-pointer"
                          id="transitioning"
                          type="checkbox"
                          value="Yes"
                          onChange={(e) =>
                            updateInputData('transitioning', e.target.checked)
                          }
                          checked={data.transitioning as boolean}
                        />
                      </label>
                      <p className="m-0">
                        Currently transitioning between companies
                        <span className="hidden sm:inline">&nbsp;or roles</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 sm:flex sm:flex-row-reverse">
                <div className="flex flex-row">
                  <Button
                    size="small"
                    type="submit"
                    variant="text-only"
                    onClick={handleCancel}
                    dataTest="member-profile-edit-save-changes"
                  >
                    Cancel
                  </Button>
                  <div className="w-5" />
                  <Button
                    size="small"
                    type="submit"
                    shape="rounded-none"
                    onClick={handleSave}
                    disabled={!canSave}
                    isLoadingSpinner={userLoading}
                    dataTest="member-profile-edit-save-changes"
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalContent>
  )
}

export function trackProfileEditModalDisplayed() {
  trackModalDisplayed({
    category: 'app',
    modal_group: 'edit profile',
    modal_name: 'edit profile'
  })
}

export function useFreeUserProfileEditModal() {
  const { openGlobalModal } = useGlobalModal()

  const openFreeUserProfileEditModal = () => {
    openGlobalModal(<FreeUserProfileEditModalContainer />, {
      className: 'lg:w-[970px] rounded-2xl',
      header: false,
      scrollContent: false
    })
    trackProfileEditModalDisplayed()
  }

  return { openFreeUserProfileEditModal }
}
